<template>
    <b-row>
        <b-col md="2" sm="4" class="my-1">
            <b-form-group class="mb-0">
                <label class="d-inline-block text-sm-left mr-50"></label>
                <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
            </b-form-group>
        </b-col>
        <b-col md="4" sm="8" class="my-1">

        </b-col>
        <b-col md="4" class="my-1">
            <b-form-group label="" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="filterInput"
                class="mb-0">
                <b-input-group size="sm">
                    <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
                </b-input-group>
            </b-form-group>
        </b-col>
        <b-col md="2" class="my-1">
            <b-button variant="info" block size="sm">
                <feather-icon size='15' icon="PlusCircleIcon"></feather-icon> Tambah Periode Pengajuan
            </b-button>
        </b-col>

        <b-col cols="12">
            <b-table striped hover responsive :per-page="perPage" :current-page="currentPage" :items="items"
                :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection"
                :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">
                <template #cell(wbs)="data">
                    <b-row>
                        <b-col md="5">
                            <small class="text-primary">Nama Balai/BPJ</small>
                            <h3 class="text-primary"><strong>{{ data.item.bpj }}</strong></h3>
                            <h6 class="text-warning"> <small><strong>Keterangan</strong> </small></h6>
                            <small style="text-align: justify;">Lorem Ipsum is simply dummy text Lorem Ipsum has been
                                the industry's standard dummy text ever since the 1500s,</small>
                        </b-col>
                        <b-col md="4">
                            <b-row>
                                <b-col md="6">
                                    <small class="text-warning">Area Kerja Kabupaten</small>
                                </b-col>
                                <b-col md="4">

                                </b-col>
                            </b-row>

                            <h6 v-for="kab in data.item.kabupaten" v-bind:key="kab"><small><strong>- {{ kab.nm_kab }}</strong></small></h6>
                            
                            <b-badge block variant="info" size="sm" class="btn-block">
                                <span ><feather-icon icon="EditIcon" size="15"></feather-icon> Kelola Kapupaten</span>
                            </b-badge>
                        </b-col>
                        <b-col md="3">

                            <b-button block variant="outline-warning" size="sm">
                                <feather-icon icon="Edit3Icon" size="15"></feather-icon> Edit
                            </b-button>
                            <b-button block variant="outline-danger" size="sm">
                                <feather-icon icon="TrashIcon" size="15"></feather-icon> Hapus
                            </b-button>
                        </b-col>
                    </b-row>
                </template>
            </b-table>
        </b-col>

        <b-col cols="12">
            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="right" size="sm"
                class="my-0" />
        </b-col>
    </b-row>
</template>

<script>
    import {
        BTable,
        BAvatar,
        BBadge,
        BRow,
        BCol,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
    } from 'bootstrap-vue'

    export default {
        components: {
            BTable,
            BAvatar,
            BBadge,
            BRow,
            BCol,
            BFormGroup,
            BFormSelect,
            BPagination,
            BInputGroup,
            BFormInput,
            BInputGroupAppend,
            BButton,
        },
        data() {
            return {
                perPage: 5,
                pageOptions: [3, 5, 10],
                totalRows: 1,
                currentPage: 1,
                sortBy: '',
                sortDesc: false,
                sortDirection: 'asc',
                filter: null,
                filterOn: [],
                infoModal: {
                    id: 'info-modal',
                    title: '',
                    content: '',
                },
                fields: [{
                    key: 'wbs',
                    label: '',
                    thStyle: {
                        display: 'none'
                    }
                }],
                items: [
                    {
                        id: 1,
                        bpj: "BPJ CILACAP",
                        kabupaten: [{
                                nm_kab: 'Kapupaten Cilacap'
                            },
                            {
                                nm_kab: 'Kapupaten Banyumas'
                            },
                            {
                                nm_kab: 'Kapupaten Purbalingga'
                            },
                        ],
                        keterangan: "Lorem Ipsum is simply dummy text Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"

                    },
                    {
                        id: 1,
                        bpj: "BPJ MAGELANG",
                        kabupaten: [{
                                nm_kab: 'Kapupaten Kebumen'
                            },
                            {
                                nm_kab: 'Kapupaten Purworejo'
                            },
                            {
                                nm_kab: 'Kapupaten Magelang'
                            },
                        ],
                        keterangan: "Lorem Ipsum is simply dummy text Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"

                    },{
                        id: 1,
                        bpj: "BPJ PATI",
                        kabupaten: [{
                                nm_kab: 'Kapupaten Kudus'
                            },
                            {
                                nm_kab: 'Kapupaten Rembang'
                            },
                            {
                                nm_kab: 'Kapupaten Jepara'
                            },
                        ],
                        keterangan: "Lorem Ipsum is simply dummy text Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"

                    },

                ],
                
            }
        },
        computed: {
            sortOptions() {
                // Create an options list from our fields
                return this.fields
                    .filter(f => f.sortable)
                    .map(f => ({
                        text: f.label,
                        value: f.key
                    }))
            },
        },
        mounted() {
            // Set the initial number of items
            this.totalRows = this.items.length
        },
        methods: {
            info(item, index, button) {
                this.infoModal.title = `Row index: ${index}`
                this.infoModal.content = JSON.stringify(item, null, 2)
                this.$root.$emit('bv::show::modal', this.infoModal.id, button)
            },
            resetInfoModal() {
                this.infoModal.title = ''
                this.infoModal.content = ''
            },
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length
                this.currentPage = 1
            },
        },
    }
</script>
<style>
    .btn-block {
        display: block;
        width: 100%;
    }
</style>