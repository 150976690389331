<template>
   <div>
    <b-row> 
       <b-col>
        <b-card>
            <tabel_data></tabel_data>
        </b-card>
       </b-col>
    </b-row>
   </div>
</template> 

<script>
import tabel_data from './component/tabel.vue';
    import {
        BCard,
        BTable,
        BAvatar,
        BBadge,
        BRow,
        BCol,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
    } from 'bootstrap-vue'

    export default {
        components: {BCard,tabel_data,
            BTable,
            BAvatar,
            BBadge,
            BRow,
            BCol,
            BFormGroup,
            BFormSelect,
            BPagination,
            BInputGroup,
            BFormInput,
            BInputGroupAppend,
            BButton,
        },
        data() {
            return {}

        },
        computed: {},
        mounted() {},
        methods: {},
    }
</script>